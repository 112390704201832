import { useEffect } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { isBrowser, useUserContext } from '~providers/user'

export const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useUserContext()
  if (isBrowser() && !(isAuthenticated() || location.pathname === '/log-in')) {
    navigate('/log-in', { state: { from: location.pathname } })
    return null
  }
  return children
}

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
}

export const GuestRoute = ({ children }) => {
  const { isAuthenticated } = useUserContext()
  useEffect(() => {
    const isGuestPage = ['/log-in', '/sign-up'].includes(location.pathname)
    if (isAuthenticated() && isGuestPage) {
      navigate('/account')
    }
  })
  return children
}

GuestRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
}
