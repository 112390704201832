import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import scssVars from '~components/scss-variables'
import Heading from './Heading'

const StyledHeading = styled(Heading)`
  position: relative;
  display: inline-block;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-weight: 200;
  letter-spacing: 0.025em;
  line-height: 1.2;
  font-size: 28px !important;
  padding-bottom: 24px;
  color: ${scssVars.darkBrown};
  &:after {
    content:"";
    position: absolute;
    display: inline-block;
    border-top: 2px solid ${scssVars.darkBrown};
    width: 50%;
    height: 0.5em;
    bottom: 0;
    left: 25%;
  }

  @media only screen and (min-width: 992px) {
    font-size: 2.75rem !important;
    overflow: hidden;
    width: 100%;
    padding-bottom: inherit;
    padding-top: inherit;
    &:after {
      width: 100%;
      left: 52%;
    }
  }
`

const HeadingContainer = styled.div`
  text-align: center;
  @media only screen and (min-width: 992px) {
    text-align: left;
  }
`

const FancyLineHeading = ({ className, type, children }) => (
  <HeadingContainer>
    <StyledHeading className={className} type={type}>{children}</StyledHeading>
  </HeadingContainer>
)

FancyLineHeading.propTypes = {
  type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  className: PropTypes.oneOfType([
    PropTypes.string
  ])
}

export default FancyLineHeading
