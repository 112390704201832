import React from 'react'
import { Row, Col, Alert, Spinner } from 'reactstrap'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Link, navigate } from 'gatsby'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'

import { useUserContext } from '~providers/user'
import scssVars from '~components/scss-variables'
import { AddressGeocode } from '~components/AddressGeocode'
import states from '../../data/states'

const StyledField = styled(Field)`
  font-size: 14px !important;
  border: none !important;
  border-bottom: 1px solid #8a8a8a !important;
  box-shadow: none !important;
  padding: 0 !important;
`

const StyledDropdown = styled(Field)`
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border-bottom: 1px solid #8a8a8a !important;
  color: ${scssVars.darkBrown} !important;
  option {
    position: absolute !important;
  }
`

const TextLink = styled(Link)`
  font-style: italic;
  font-weight: 500;
  text-decoration: underline;
`

const PolicyLink = styled(Link)`
  font-size: inherit;
  font-family: inherit;
`

const SignUpForm = () => {
  const { useSignUp } = useUserContext()
  const [signUp] = useSignUp()

  const onSubmit = async (
    {
      email,
      password,
      passwordConfirmation,
      firstName,
      lastName,
      phoneNumber,
      address
    },
    actions
  ) => {
    try {
      const { data: { signUp: { me, errors } } } = await signUp(
        email,
        firstName,
        lastName,
        phoneNumber,
        address,
        password,
        passwordConfirmation
      )
      if (!me || me === null) {
        actions.setErrors(errors)
        actions.setStatus({ msg: errors[0] })
        actions.setSubmitting(false)
      } else {
        actions.setSubmitting(false)
        navigate('/account')
      }
    } catch (err) {
      actions.setErrors(err)
      actions.setStatus({ msg: err.message })
      actions.setSubmitting(false)
    }
  }

  const validate = (values) => {
    const errors = {}
    if (!values.firstName) {
      errors.firstName = 'First name is required'
    } else if (!values.lastName) {
      errors.lastName = 'Last name is required'
    } else if (!values.email) {
      errors.email = 'Email address is required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    } else if (!values.address.street) {
      errors.address = 'Street is required'
    } else if (!values.address.city) {
      errors.city = 'City is required'
    } else if (!values.address.postalCode) {
      errors.postalCode = 'Postal code is required'
    } else if (!values.address.stateCode) {
      errors.stateCode = 'State is required'
    } else if (!values.password) {
      errors.password = 'Password is required'
    } else if (!values.passwordConfirmation) {
      errors.passwordConfirmation = 'Password confirmation is required'
    } else if (!values.phoneNumber) {
      errors.phoneNumber = 'Phone number is required'
    }
    return errors
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        passwordConfirmation: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        address: {
          street: '',
          addressLine2: '',
          city: '',
          postalCode: '',
          stateCode: '',
          mapboxId: '',
          longitude: null,
          latitude: null
        }
      }}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        values,
        setSubmitting,
        setStatus,
        setErrors
      }) => (
        <Form>
          <Row>
            <Col>
              <StyledField className="form-control mb-3" placeholder="First name*" type="text" name="firstName" required />
            </Col>
            <Col>
              <StyledField className="form-control mb-3" placeholder="Last name*" type="text" name="lastName" required />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledField className="form-control mb-3" placeholder="Email address*" type="email" name="email" required />
            </Col>
            <Col>
              <Field>
                {({ field, form, meta }) => (
                  <NumberFormat
                    className="form-control mb-3"
                    type="tel" format="+1 ### ### ####"
                    customInput={StyledField}
                    placeholder="Phone number*"
                    onValueChange={values => form.setFieldValue('phoneNumber', values.formattedValue)}
                    required
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Field>
            {({ form }) => (
              <AddressGeocode name="address[street]" placeholder="Address / line 1*" type="text" required form={form} className="form-control mb-3" onSelected={(address) => {
                if (address?.mapboxId) {
                  form.setFieldValue('address[street]', address.localPart)
                  form.setFieldValue('address[city]', address.city)
                  form.setFieldValue('address[postalCode]', address.postalCode)
                  form.setFieldValue('address[stateCode]', address.stateCode)
                  form.setFieldValue('address[mapboxId]', address.mapboxId)
                  form.setFieldValue('address[latitude]', address.coordinates[1])
                  form.setFieldValue('address[longitude]', address.coordinates[0])
                } else {
                  // ensure lat/long are null, not empty strings
                  form.setFieldValue('address[latitude]', null)
                  form.setFieldValue('address[longitude]', null)
                }
              }} />
            )}
          </Field>
          <StyledField className="form-control mb-3" placeholder="Address / line 2" type="text" name="address[addressLine2]" />
          <Row>
            <Col>
              <StyledField className="form-control mb-3" placeholder="City*" type="text" name="address[city]" required />
            </Col>
            <Col>
              <StyledField className="form-control mb-3" placeholder="Postal code*" type="text" name="address[postalCode]" required />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledDropdown className="form-control mb-3" component="select" name="address[stateCode]" required>
                <option>State*</option>
                {states.map((state, i) => (<option key={i} value={state.abbreviation}>{state.name}</option>))}
              </StyledDropdown>
            </Col>
            <Col>
              <StyledField className="form-control mb-3" placeholder="Country" type="text" name="address[country]" value="United States" readOnly />
            </Col>
          </Row>
          <Field type="hidden" name="address[mapboxId]" />
          <Field type="hidden" name="address[latitude]" />
          <Field type="hidden" name="address[longitude]" />
          <StyledField className="form-control mb-3" placeholder="Password*" type="password" name="password" required />
          <StyledField className="form-control mb-3" placeholder="Confirm password*" type="password" name="passwordConfirmation" required />
          {['firstName', 'lastName', 'email', 'password', 'phoneNumber'].map((fieldName, i) => (
            <ErrorMessage name={fieldName} key={i}>
              {errMsg => <Alert color="danger">{errMsg}</Alert>}
            </ErrorMessage>
          ))}
          <p>By signing up, you agree to our <PolicyLink to="/privacy">Privacy Policy</PolicyLink> & <PolicyLink to="/terms-conditions">Terms</PolicyLink></p>
          <button className="btn btn-primary w-100" type="submit" disabled={isSubmitting}>
            {isSubmitting ? <Spinner color="light" /> : 'Sign Up'}
          </button>
          <div className="my-3">
            <TextLink to="/log-in">Already have an account? Log in</TextLink>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default SignUpForm
