import React from 'react'
import PropTypes from 'prop-types'

const Heading = ({ type, children, ...props }) => React.createElement(type, props, children)

Heading.propTypes = {
  type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ])
}

export default Heading
